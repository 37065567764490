// Typography
$base-font-family: 'Open Sans', $helvetica;
// $base-font-family: 'Merriweather', "Georgia", serif;
$heading-font-family: $base-font-family;

// Font Sizes
$base-font-size: 1em;

// Line height
$base-line-height: 1.5;
$heading-line-height: 1.2;

// Other Sizes
$base-border-radius: .52em;
$base-spacing: $base-line-height * 1em;
$small-spacing: $base-spacing / 2;
$base-z-index: 0;

// Font Colors
$base-font-color: var(--t);

// Border
$base-border-color: var(--bp);
$base-border: 1px solid $base-border-color;

// Forms
$form-box-shadow: inset 0 1px 3px var(--tm);
$form-box-shadow-focus: $form-box-shadow, 0 0 5px var(--tm);

// Animations
$base-duration: 150ms;
$base-timing: ease;
