fieldset {
  background-color: var(--bp);
  border: $base-border;
  margin: 0 0 $small-spacing;
  padding: $base-spacing;
}

input,
label,
select {
  display: block;
  font-family: $base-font-family;
  font-size: $base-font-size;
}

label {
  font-weight: 600;
  margin-bottom: $small-spacing / 2;

  &.required::after {
    content: "*";
  }

  abbr {
    display: none;
  }
}

#{$all-text-inputs},
select[multiple=multiple] {
  background-color: var(--b);
  border: $base-border;
  border-radius: $base-border-radius;
  box-shadow: $form-box-shadow;
  box-sizing: border-box;
  font-family: $base-font-family;
  font-size: $base-font-size;
  margin-bottom: $small-spacing;
  padding: $base-spacing / 3;
  transition: border-color $base-duration $base-timing;
  width: 100%;

  &:hover {
    border-color: var(--bp);
  }

  &:focus {
    border-color: var(--u);
    box-shadow: $form-box-shadow-focus;
    outline: none;
  }

  &:disabled {
    background-color: var(--bp);
    cursor: not-allowed;

    &:hover {
      border: $base-border;
    }
  }
}

textarea {
  resize: vertical;
}

input[type="search"] {
  appearance: none;
}

input[type="checkbox"],
input[type="radio"] {
  display: inline;
  margin-right: $small-spacing / 2;

  + label {
    display: inline-block;
  }
}

input[type="file"] {
  margin-bottom: $small-spacing;
  width: 100%;
}

select {
  margin-bottom: $base-spacing;
  max-width: 100%;
  width: auto;
}
