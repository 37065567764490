body {
  color: $base-font-color;
  font-family: $base-font-family;
  font-feature-settings: "kern", "liga", "pnum";
  font-size: $base-font-size;
  line-height: $base-line-height;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $heading-font-family;
  font-size: $base-font-size;
  line-height: $heading-line-height;
  margin: 0 0 $small-spacing;
  font-weight: 300;
}

h1 {
  font-size: rem(30);
}

h2 {
  font-size: rem(26);
  padding: 0.35em 0 0 0;
}

h3 {
  font-size: rem(24);
  padding: 0.25em 0 0 0;
}

h4 {
  font-size: rem(20);
  padding: 0.20em 0 0 0;
}

h5,
h6 {
  font-size: rem(16);
}

em {
  font-style: italic;
}

strong {
  font-weight: bold;
}

sub {
  vertical-align: sub;
  font-size: smaller;
}

sup {
  vertical-align: super;
  font-size: smaller;
}

p {
  margin: 0 0 $small-spacing;

  &.lead {
    font-size: rem(22);
    font-weight: 300;
  }
}

a {
  color: var(--u);
  text-decoration: none;
  transition: color $base-duration $base-timing;

  &:active,
  &:focus,
  &:hover {
    color: var(--uh);
  }
}

hr {
  border-bottom: $base-border;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  margin: $base-spacing 0;
}

img,
picture {
  display: block;
  margin: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
}

blockquote {
  padding: 0 0 0 $base-spacing;
  margin: $base-spacing 0;
  color: var(--tm);
  line-height: 1.8;
  border-left: $small-spacing solid var(--bp);
}

code {
  background: none;
  border-radius: 0;
  border: none;
  font-family: "Courier New", monospace;
  font-size: 0.9em;
  margin: 0;
  padding: 0 5px;
  background-color: var(--bp);
}

pre {
  -webkit-overflow-scrolling: touch;
  font-family: "Courier New", monospace;
  font-size: 0.9em;
  margin: 0;

  code {
    line-height: 1.75em;
  }
}

// Open Sans (Latin)
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Open Sans Light'), local('OpenSans-Light'), url(/fonts/opensans-light.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans Regular'), local('OpenSans-Regular'), url(/fonts/opensans-regular.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Open Sans Bold'), local('OpenSans-Bold'), url(/fonts/opensans-bold.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* Open Sans (Latin Extended)
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Open Sans Light'), local('OpenSans-Light'), url(https://fonts.gstatic.com/s/opensans/v17/mem5YaGs126MiZpBA-UN_r8OXOhpOqc.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans Regular'), local('OpenSans-Regular'), url(https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFW50bbck.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Open Sans Bold'), local('OpenSans-Bold'), url(https://fonts.gstatic.com/s/opensans/v17/mem5YaGs126MiZpBA-UN7rgOXOhpOqc.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
*/

// Based on Socicon, slimmed with Baidu FontStore
@font-face {
  font-family: 'Entities';
  font-style: normal;
  font-weight: normal;
  src: local('Entities'), url(/fonts/entities.woff2) format('woff2');
}

[class^="entities-"], [class*=" entities-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Entities';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.entities-amazon:before {
  content: "\e004";
}
.entities-facebook:before {
  content: "\e028";
}
.entities-github:before {
  content: "\e032";
}
.entities-google:before {
  content: "\e034";
}
.entities-instagram:before {
  content: "\e044";
}
.entities-keybase:before {
  content: "\e048";
}
.entities-linkedin:before {
  content: "\e04c";
}
.entities-patreon:before {
  content: "\e05f";
}
.entities-paypal:before {
  content: "\e060";
}
.entities-reddit:before {
  content: "\e06c";
}
.entities-stackoverflow:before {
  content: "\e07b";
}
.entities-telegram:before {
  content: "\e087";
}
.entities-tumblr:before {
  content: "\e08b";
}
.entities-twitch:before {
  content: "\e08c";
}
.entities-twitter:before {
  content: "\e08d";
}
.entities-wikipedia:before {
  content: "\e09a";
}
.entities-youtube:before {
  content: "\e0a5";
}
.entities-slack:before {
  content: "\e918";
}
.entities-gitlab:before {
  content: "\e945";
}
.entities-hackerone:before {
  content: "\e956";
}
.entities-hackernews:before {
  content: "\e946";
}
.entities-sym-sun:before {
  content: "\e957";
}
.entities-sym-moon:before {
  content: "\e958";
}