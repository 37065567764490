* {
  &,
  &:before,
  &:after {
    box-sizing: border-box;
  }
}

html,
body {
  width: 100%;
  height: 100%;
}

.clearfix {
  &:before {
    content: " ";
    display: table;
  }

  &:after {
    content: " ";
    display: table;
    clear: both;
  }

  *zoom: 1;
}

body {
  background-color: var(--b);
  font-weight: 400;
}

hr {
  border-top: none;
  border-bottom: 2px solid var(--bp);
  width: 100%;
  margin: $small-spacing 0;
}

.container {
  padding: $base-spacing;
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.link {
  display: inline-block;
  padding-bottom: 0;
  border-bottom: 1px solid var(--l);

  @include transition(border 300ms linear);

  &:hover,
  &:focus,
  &:active {
    border-color: var(--lh);
  }
}

.site-header {
  border-bottom: 1px solid var(--l);
  margin-bottom: $small-spacing;
  min-height: 80px;
}

.intro {
  .author-name {
    background-color: var(--u);
    color: var(--g);
    display: inline-block;
    padding: 0 .3em;
  }

  a {
    @extend .link;
  }
}

.nav {
  min-height: 64px;
}

.navbar {
  float: right;
  margin: 0;
  position: relative;
  padding: 0;
  pointer-events: all;
  cursor: pointer;

  li {
    display: inline-block;
    padding: 0 .6em;
  }

  a {
    @extend .link;
  }
}

.post-list {
  background-color: var(--bp);
  padding: $base-spacing 0;
}

.post-item {
  padding-bottom: $small-spacing;
  margin-bottom: $base-spacing;
  border-bottom: 1px solid var(--l);

  strong {
    font-weight: 700;
  }

  &:last-child {
    border-bottom: 0;
    margin-bottom: 0;
  }

  .readmore {
    display: inline-block;
    font-style: italic;
  }
}

.post-meta {
  color: var(--tm);;
  font-style: italic;
}

.post-link {
  @extend .link;
  display: inline;
}

.post a,
.page a {
  @extend .link;
}

.post sup {
  a {
    border-bottom: 0px;
  }
}

.post {
  @extend .clearfix;
}

.pagination {
  li, a, span {
    display: inline-block;
  }

  a, span {
    font-size: rem(12);
    padding: .5em;
  }

  .prev, .next {
    @extend .link;
  }
}

.share {
  padding: $base-spacing 0 0;

  @extend .pull-right;
}

.site-footer {
  @extend .clearfix;

  padding: $base-spacing 0;

  a {
    @extend .link;
  }

  small {
    display: block;
    font-size: rem(12);
    color: var(--tm);;
  }
}

.footnotes {
  ol {
    list-style-type: decimal;
  }
}