html,
html[data-theme="dark"] {
    --tm: #b1b3b6; // text-muted
    --t: #cbccce; // text
    --u: #7660ff; // url
    --uh: #836fff; // url-hover
    --b: #18191c; // background
    --bp: #212221; // background-pop
    --l: #4a4d58; // line
    --lh: #525560; // line-hover
    --g: #f1f1f5; // ghost
    --s1: #eb6f9d; // svg-lightest
    --s2: #e44e7d; // svg-light
    --s3: #de2b5e; // svg-mid
    --s4: #c11f4b; // svg-dark
    --s5: #9d1b3b; // svg-darkest
}

html[data-theme="light"] {
    --tm: #6b7080; // text-muted
    --t: #525560; // text
    --u: #574ae2; // url
    --uh: #715aff; // url-hover
    --b: #ffffff; // background
    --bp: #f7f7f7; // background-pop
    --l: #bfc2cd; // line
    --lh: #c6c9d4; // line-hover
    --g: #f1f1f5; // ghost
    --s1: #e74b84; // svg-lightest
    --s2: #e74b84; // svg-light
    --s3: #c21e4d; // svg-mid
    --s4: #9e1a3d; // svg-dark
    --s5: #7a152e; // svg-darkest
}

@import "bourbon";

@import "variables";
@import "base/base";

@import "layout";
@import "syntax-highlighting";
